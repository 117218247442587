import React, { useEffect, useRef, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap is imported
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../css/Pricing.css' 
import '../css/Main.css' 
import arrowup from '../assets/arrow-trend-up-solid.svg';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
import Convertlab from '../assets/Portfolio/convertlab-main.png';
import Dalle from '../assets/Portfolio/dalle-designs-main.png';
import Gluteflex from '../assets/Portfolio/gluteflex-main.png';
import Nosnore from '../assets/Portfolio/nosnore-main.png';
import star from '../assets/star-solid.svg';
import Oliver from '../assets/oliver.png';
import Check from '../assets/check.svg';
import Calendar from '../assets/calendar.svg';


const Pricing = () => {
    const [selectedOption, setSelectedOption] = useState("WEB DESIGN"); // Default active option is SEO
    const pillsContainerRef = useRef(null);
    const slidingBackgroundRef = useRef(null);
  
    // Utility function to detect mobile devices
    const isMobile = () => window.innerWidth <= 768;
  
    useEffect(() => {
      // Only initialize AOS if the device is not mobile
      if (!isMobile()) {
        AOS.init({
          once: true, // Animations will happen only once
        });
      }
  
      const pillsContainer = pillsContainerRef.current;
      const slidingBackground = slidingBackgroundRef.current;
      const pills = pillsContainer.querySelectorAll('.nav-link');
  
      const updateBackgroundPosition = (activePill) => {
        const pillRect = activePill.getBoundingClientRect();
        const containerRect = pillsContainer.getBoundingClientRect();
        const offsetX = pillRect.left - containerRect.left;
  
        slidingBackground.style.width = `${pillRect.width}px`;
        slidingBackground.style.transform = `translateX(${offsetX}px)`;
      };
  
      // Initialize with the active pill
      const activePill = pillsContainer.querySelector('.nav-link.active');
      if (activePill) {
        updateBackgroundPosition(activePill);
      }
  
      // Event listener for changing active pills
      pills.forEach(pill => {
        pill.addEventListener('click', (event) => {
          // Remove active class from all pills
          pills.forEach(p => p.classList.remove('active'));
          // Add active class to the clicked pill
          event.target.classList.add('active');
          // Update the sliding background
          updateBackgroundPosition(event.target);
          setSelectedOption(event.target.textContent.trim().toUpperCase());
        });
      });
  
      // Cleanup the event listeners on component unmount
      return () => {
        pills.forEach(pill => {
          pill.removeEventListener('click', updateBackgroundPosition);
        });
      };
    }, []);

  return (
    <div className='Pricing pt-5'>
        
        <div className='container'>
            <div className='row'>
                <div  data-aos="" className='col-12 text-lg-start text-md-start text-center mb'>
                <h1><span className='span-tag2'>PRICING</span>Profitable Pricing Plans</h1>
                <p>Select the Service Type That Suits You</p>

                <ul className='nav nav-pills mb-4 justify-content-center desktop-pills' ref={pillsContainerRef} id='pills-tab' role='tablist'>
                    <div className='sliding-background' ref={slidingBackgroundRef}></div>
                    <li className='nav-item' role='presentation'>
                    <button className='nav-link active' id='pills-webdesign-tab' data-bs-toggle='pill' data-bs-target='#pills-webdesign' type='button' role='tab' aria-controls='pills-webdesign' aria-selected='false'>
                        WEB DESIGN
                    </button>
                    </li>
                    <li className='nav-item' role='presentation'>
                    <button className='nav-link' id='pills-seo-tab' data-bs-toggle='pill' data-bs-target='#pills-seo' type='button' role='tab' aria-controls='pills-seo' aria-selected='true'>
                        SEO
                    </button>
                    </li>
                    <li className='nav-item' role='presentation'>
                    <button className='nav-link' id='pills-googleads-tab' data-bs-toggle='pill' data-bs-target='#pills-googleads' type='button' role='tab' aria-controls='pills-googleads' aria-selected='false'>
                        GOOGLE ADS
                    </button>
                    </li>
                    <li className='nav-item' role='presentation'>
                    <button className='nav-link' id='pills-facebookads-tab' data-bs-toggle='pill' data-bs-target='#pills-facebookads' type='button' role='tab' aria-controls='pills-facebookads' aria-selected='false'>
                        FACEBOOK ADS
                    </button>
                    </li>
                    <li className='nav-item' role='presentation'>
                    <button className='nav-link' id='pills-socialmedia-tab' data-bs-toggle='pill' data-bs-target='#pills-socialmedia' type='button' role='tab' aria-controls='pills-socialmedia' aria-selected='false'>
                        SOCIAL MEDIA
                    </button>
                    </li>
                </ul>

                <div className="mobile-dropdown mb-4">
                    <select
                    value={selectedOption}
                    onChange={(e) => setSelectedOption(e.target.value)}
                    className="form-select"
                    aria-labelledby={`pills-${selectedOption.toLowerCase()}-tab`}
                    >
                    <option value="WEB DESIGN">WEB DESIGN</option>
                    <option value="SEO">SEO</option>
                    <option value="GOOGLE ADS">GOOGLE ADS</option>
                    <option value="FACEBOOK ADS">FACEBOOK ADS</option>
                    <option value="SOCIAL MEDIA">SOCIAL MEDIA</option>
                    </select>
                </div>


                </div>
            </div>
        </div>

        <div className='container review-row '>

        <div class="tab-content" id="pills-tabContent">
        <   div className={`tab-pane fade show ${selectedOption === "SEO" ? "active" : ""}`} id="pills-seo" role="tabpanel" aria-labelledby="pills-seo-tab">
        <div className='row justify-content-center'>
            <div className='col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12 mb-4' data-aos="fade-up" data-aos-delay="00">
                <div className='pricing-box'>
                    <h3>Essential SEO</h3>
                    <p>Perfect for small sites with limited content, ideal for startups or personal brands.</p>
                    <p><span className='pricing-box-price-first'>From </span><span className='pricing-box-price'>£72+</span> <span className='pricing-box-price-last'>/ Month</span></p>
                    <Link
                            data-aos="fade-up"
                            data-aos-delay="00"
                            to='/book-a-meeting'
                            className="btn"
                          >
                            SCHEDULE A CALL <img style={{ marginTop: '-4px', marginLeft: '5px' }} src={Calendar} width="25px " alt='calendar-icon'></img>
                          </Link>
                    <ul>
                        <li><img src={Check} width="30px" alt="check"></img> On-page SEO optimisation</li>
                        <li><img src={Check} width="30px" alt="check"></img> Meta tags and descriptions</li>
                        <li><img src={Check} width="30px" alt="check"></img> Keyword research for 1-3 key terms</li>
                        <li><img src={Check} width="30px" alt="check"></img> Google My Business setup</li>
                        <li><img src={Check} width="30px" alt="check"></img> Monthly SEO performance report</li>
                    </ul>
                </div>
            </div>

            <div className='col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12 mb-4' data-aos="fade-up" data-aos-delay="00">
                <div className='pricing-box'>
                    <h3>Growth SEO</h3>
                    <p>Ideal for mid-sized businesses looking to enhance their online visibility and performance.</p>
                    <p><span className='pricing-box-price-first'>From </span><span className='pricing-box-price'>£180+</span> <span className='pricing-box-price-last'>/ Month</span></p>
                    <Link
                            data-aos="fade-up"
                            data-aos-delay="00"
                            to='/book-a-meeting'
                            className="btn"
                          >
                            SCHEDULE A CALL <img style={{ marginTop: '-4px', marginLeft: '5px' }} src={Calendar} width="25px " alt='calendar-icon'></img>
                          </Link>
                    <ul>
                        <li><img src={Check} width="30px" alt="check"></img> Advanced keyword research and targeting</li>
                        <li><img src={Check} width="30px" alt="check"></img> On-page and technical SEO</li>
                        <li><img src={Check} width="30px" alt="check"></img> Content optimisation (blogs, services)</li>
                        <li><img src={Check} width="30px" alt="check"></img> Internal linking strategy</li>
                        <li><img src={Check} width="30px" alt="check"></img> SEO tracking and analytics integration</li>
                    </ul>
                </div>
            </div>

            <div className='col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12 mb-4' data-aos="fade-up" data-aos-delay="00">
                <div className='pricing-box pricing-box-enterprise'>
                    <h3>Enterprise SEO</h3>
                    <p>A comprehensive solution for large businesses requiring advanced SEO strategies and optimisation.</p>
                    <p><span className='pricing-box-price-first'>Lets Talk!</span></p>
                    <Link
                            data-aos="fade-up"
                            data-aos-delay="00"
                            to='/book-a-meeting'
                            className="btn"
                          >
                            SCHEDULE A CALL <img style={{ marginTop: '-4px', marginLeft: '5px' }} src={Calendar} width="25px " alt='calendar-icon'></img>
                          </Link>
                    <ul>
                        <li><img src={Check} width="30px" alt="check"></img> Custom SEO strategy for large sites</li>
                        <li><img src={Check} width="30px" alt="check"></img> Multi-level content optimisation (blogs, product pages, services)</li>
                        <li><img src={Check} width="30px" alt="check"></img> Advanced technical SEO (schema markup, mobile-first indexing)</li>
                        <li><img src={Check} width="30px" alt="check"></img> Monthly in-depth SEO analysis and reporting</li>
                        <li><img src={Check} width="30px" alt="check"></img> Ongoing SEO support and consultation</li>
                    </ul>
                </div>
            </div>
        </div>

                </div>

                <div className={`tab-pane fade show ${selectedOption === "WEB DESIGN" ? "active" : ""}`} id="pills-webdesign" role="tabpanel" aria-labelledby="pills-webdesign-tab">
                    <div className='row justify-content-center'>

                        <div className='col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12 mb-4' data-aos="fade-up" data-aos-delay="00">
                        <div className='pricing-box'>
                            <h3>Essential Plan</h3>
                            <p>Perfect for small businesses or personal brands. Includes everything needed for a professional online presence.</p>
                            <p><span className='pricing-box-price-first'>From </span><span className='pricing-box-price'>£149+</span> <span className='pricing-box-price-last'>/ Month</span></p>
                            <Link
                            data-aos="fade-up"
                            data-aos-delay="00"
                            to='/book-a-meeting'
                            className="btn"
                          >
                            SCHEDULE A CALL <img style={{ marginTop: '-4px', marginLeft: '5px' }} src={Calendar} width="25px " alt='calendar-icon'></img>
                          </Link>
                            <ul>
                            <li><img src={Check} width="50px" alt="check"></img> Custom website design</li>
                            <li><img src={Check} width="50px" alt="check"></img> Web hosting and domain registration</li>
                            <li><img src={Check} width="50px" alt="check"></img> Custom email addresses</li>
                            <li><img src={Check} width="50px" alt="check"></img> SEO-friendly design</li>
                            <li><img src={Check} width="50px" alt="check"></img> Dedicated support</li>
                            </ul>
                        </div>
                        </div>

                        <div className='col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12 mb-4' data-aos="fade-up" data-aos-delay="00">
                            <div className='pricing-box'>
                                <h3>Growth Plan</h3>
                                <p>Ideal for growing businesses looking for more advanced features and a site that grows with them.</p>
                                <p><span className='pricing-box-price-first'>From </span><span className='pricing-box-price'>£229+</span> <span className='pricing-box-price-last'>/ Month</span></p>
                               <Link
                            data-aos="fade-up"
                            data-aos-delay="00"
                            to='/book-a-meeting'
                            className="btn"
                          >
                            SCHEDULE A CALL <img style={{ marginTop: '-4px', marginLeft: '5px' }} src={Calendar} width="25px " alt='calendar-icon'></img>
                          </Link>
                                <ul>
                                <li><img src={Check} width="30px" alt="check"></img> Advanced custom website design</li>
                                <li><img src={Check} width="30px" alt="check"></img> Web hosting, domain, and emails included</li>
                                <li><img src={Check} width="30px" alt="check"></img> Content management system (CMS) integration</li>
                                <li><img src={Check} width="30px" alt="check"></img> SEO setup with targeted keywords</li>
                                <li><img src={Check} width="30px" alt="check"></img> Monthly website performance report</li>
                                </ul>
                            </div>
                            </div>

                            <div className='col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12 mb-4' data-aos="fade-up" data-aos-delay="00">
                            <div className='pricing-box pricing-box-enterprise'>
                                <h3>Enterprise Plan</h3>
                                <p>A comprehensive solution for large businesses requiring advanced features and extensive content management.</p>
                                <p><span className='pricing-box-price-first'>Lets Talk!</span></p>
                               <Link
                            data-aos="fade-up"
                            data-aos-delay="00"
                            to='/book-a-meeting'
                            className="btn"
                          >
                            SCHEDULE A CALL <img style={{ marginTop: '-4px', marginLeft: '5px' }} src={Calendar} width="25px " alt='calendar-icon'></img>
                          </Link>
                                <ul>
                                <li><img src={Check} width="30px" alt="check"></img> Fully customised website with advanced features</li>
                                <li><img src={Check} width="30px" alt="check"></img> Web hosting, domain, and emails included</li>
                                <li><img src={Check} width="30px" alt="check"></img> E-commerce integration (if applicable)</li>
                                <li><img src={Check} width="30px" alt="check"></img> Continuous technical and content support</li>
                                <li><img src={Check} width="30px" alt="check"></img> Advanced analytics and SEO strategies</li>
                                </ul>
                            </div>
                            </div>
                        
                    </div>
                    </div>


            <div className={`tab-pane fade show ${selectedOption === "GOOGLE ADS" ? "active" : ""}`} id="pills-googleads" role="tabpanel" aria-labelledby="pills-googleads-tab">
            <div className='row justify-content-center'>

                <p className='text-center text-sm-center text-xs-center text-md-start text-lg-start'>Our pricing ranges from just 3% to 10% of your ad spend, compared to the typical 15% to 20%—because delivering value shouldn’t come with inflated costs.</p>
  
                <div className='col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12 mb-4' data-aos="fade-up" data-aos-delay="00">
                    <div className='pricing-box'>
                    <h3>Essential Plan</h3>
                    <p>Google Ads management for small-scale ad spend, including campaign setup, keyword targeting, and ongoing optimisation.</p>
                    <p><span className='pricing-box-price-first'>From </span><span className='pricing-box-price'>£500+</span> <span className='pricing-box-price-last'>/ Month</span></p>
                    <Link
                            data-aos="fade-up"
                            data-aos-delay="00"
                            to='/book-a-meeting'
                            className="btn"
                          >
                            SCHEDULE A CALL <img style={{ marginTop: '-4px', marginLeft: '5px' }} src={Calendar} width="25px " alt='calendar-icon'></img>
                          </Link>
                    <ul>
                        <li><img src={Check} width="30px" alt="check"></img> Campaign Setup & Management</li>
                        <li><img src={Check} width="30px" alt="check"></img> Keyword Targeting & Research</li>
                        <li><img src={Check} width="30px" alt="check"></img> Ongoing Optimisation</li>
                        <li><img src={Check} width="30px" alt="check"></img> Ad Copy Refinement</li>
                        <li><img src={Check} width="30px" alt="check"></img> Monthly Ad Account Updates</li>
                        <li><img src={Check} width="30px" alt="check"></img> Performance Tracking & Insights</li>
                    </ul>
                    </div>
                </div>

                <div className='col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12 mb-4' data-aos="fade-up" data-aos-delay="00">
                    <div className='pricing-box'>
                    <h3>Growth Plan</h3>
                    <p>Advanced ad spend management with dedicated keyword research, audience segmentation, and conversion tracking.</p>
                    <p><span className='pricing-box-price-first'>From </span><span className='pricing-box-price'>£1000+</span> <span className='pricing-box-price-last'>/ Month</span></p>
                    <Link
                            data-aos="fade-up"
                            data-aos-delay="00"
                            to='/book-a-meeting'
                            className="btn"
                          >
                            SCHEDULE A CALL <img style={{ marginTop: '-4px', marginLeft: '5px' }} src={Calendar} width="25px " alt='calendar-icon'></img>
                          </Link>
                    <ul>
                        <li><img src={Check} width="30px" alt="check"></img> Advanced Campaign Management</li>
                        <li><img src={Check} width="30px" alt="check"></img> Dedicated Keyword Research & Audience Segmentation</li>
                        <li><img src={Check} width="30px" alt="check"></img> Conversion Tracking & Landing Page Optimisation</li>
                        <li><img src={Check} width="30px" alt="check"></img> A/B Testing & Bid Strategy Adjustments</li>
                        <li><img src={Check} width="30px" alt="check"></img> Weekly Ad Account Updates</li>
                        <li><img src={Check} width="30px" alt="check"></img> Comprehensive Ad Strategy & Reports</li>
                    </ul>
                    </div>
                </div>

                <div className='col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12 mb-4' data-aos="fade-up" data-aos-delay="00">
                    <div className='pricing-box pricing-box-enterprise'>
                    <h3>Enterprise Plan</h3>
                    <p>Large-scale ad spend management, focused on scaling campaigns, high-frequency optimisation, and ROI analysis.</p>
                    <p><span className='pricing-box-price-first'>Lets Talk!</span></p>
                    <Link
                            data-aos="fade-up"
                            data-aos-delay="00"
                            to='/book-a-meeting'
                            className="btn"
                          >
                            SCHEDULE A CALL <img style={{ marginTop: '-4px', marginLeft: '5px' }} src={Calendar} width="25px " alt='calendar-icon'></img>
                          </Link>
                    <ul>
                        <li><img src={Check} width="30px" alt="check"></img> Full-Scale Campaign Management</li>
                        <li><img src={Check} width="30px" alt="check"></img> High-Frequency Optimisation</li>
                        <li><img src={Check} width="30px" alt="check"></img> Detailed ROI Analysis</li>
                        <li><img src={Check} width="30px" alt="check"></img> Automation & Scaling Strategies</li>
                        <li><img src={Check} width="30px" alt="check"></img> Weekly Ad Account Updates & Detailed Reporting</li>
                        <li><img src={Check} width="30px" alt="check"></img> Customised Enterprise-Level Solutions</li>
                    </ul>
                    </div>
                </div>

                </div>

            </div>

            <div className={`tab-pane fade show ${selectedOption === "FACEBOOK ADS" ? "active" : ""}`} id="pills-facebookads" role="tabpanel" aria-labelledby="pills-facebookads-tab">
            <div className='row justify-content-center'>

                <p className='text-center text-sm-center text-xs-center text-md-start text-lg-start'>Our pricing ranges from just 3% to 10% of your ad spend, compared to the typical 15% to 20%—because delivering value shouldn’t come with inflated costs.</p>

                <div className='col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12 mb-4' data-aos="fade-up" data-aos-delay="00">
                <div className='pricing-box'>
                    <h3>Essential Plan</h3>
                    <p>Facebook Ads management for small-scale ad spend, including campaign setup, audience targeting, and ongoing optimisation.</p>
                    <p><span className='pricing-box-price-first'>From </span><span className='pricing-box-price'>£500+</span> <span className='pricing-box-price-last'>/ Month</span></p>
                    <Link
                            data-aos="fade-up"
                            data-aos-delay="00"
                            to='/book-a-meeting'
                            className="btn"
                          >
                            SCHEDULE A CALL <img style={{ marginTop: '-4px', marginLeft: '5px' }} src={Calendar} width="25px " alt='calendar-icon'></img>
                          </Link>
                    <ul>
                    <li><img src={Check} width="30px" alt="check"></img> Campaign Setup & Management</li>
                    <li><img src={Check} width="30px" alt="check"></img> Audience Targeting & Research</li>
                    <li><img src={Check} width="30px" alt="check"></img> Ongoing Optimisation</li>
                    <li><img src={Check} width="30px" alt="check"></img> Ad Copy Refinement</li>
                    <li><img src={Check} width="30px" alt="check"></img> Monthly Ad Account Updates</li>
                    <li><img src={Check} width="30px" alt="check"></img> Performance Tracking & Insights</li>
                    </ul>
                </div>
                </div>

                <div className='col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12 mb-4' data-aos="fade-up" data-aos-delay="00">
                <div className='pricing-box'>
                    <h3>Growth Plan</h3>
                    <p>Advanced ad spend management with dedicated audience research, ad creative optimisation, and conversion tracking.</p>
                    <p><span className='pricing-box-price-first'>From </span><span className='pricing-box-price'>£1000+</span> <span className='pricing-box-price-last'>/ Month</span></p>
                    <Link
                            data-aos="fade-up"
                            data-aos-delay="00"
                            to='/book-a-meeting'
                            className="btn"
                          >
                            SCHEDULE A CALL <img style={{ marginTop: '-4px', marginLeft: '5px' }} src={Calendar} width="25px " alt='calendar-icon'></img>
                          </Link>
                    <ul>
                    <li><img src={Check} width="30px" alt="check"></img> Advanced Campaign Management</li>
                    <li><img src={Check} width="30px" alt="check"></img> Audience Segmentation & Research</li>
                    <li><img src={Check} width="30px" alt="check"></img> Ad Creative Optimisation</li>
                    <li><img src={Check} width="30px" alt="check"></img> Conversion Tracking & Reporting</li>
                    <li><img src={Check} width="30px" alt="check"></img> Weekly Ad Account Updates</li>
                    <li><img src={Check} width="30px" alt="check"></img> Custom Audience Strategies & Insights</li>
                    </ul>
                </div>
                </div>

                <div className='col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12 mb-4' data-aos="fade-up" data-aos-delay="00">
                <div className='pricing-box pricing-box-enterprise'>
                    <h3>Enterprise Plan</h3>
                    <p>Large-scale ad spend management, focused on scaling campaigns, high-frequency optimisation, and ROI analysis.</p>
                    <p><span className='pricing-box-price-first'>Lets Talk!</span></p>
                    <Link
                            data-aos="fade-up"
                            data-aos-delay="00"
                            to='/book-a-meeting'
                            className="btn"
                          >
                            SCHEDULE A CALL <img style={{ marginTop: '-4px', marginLeft: '5px' }} src={Calendar} width="25px " alt='calendar-icon'></img>
                          </Link>
                    <ul>
                    <li><img src={Check} width="30px" alt="check"></img> Full-Scale Campaign Management</li>
                    <li><img src={Check} width="30px" alt="check"></img> High-Frequency Optimisation & Scaling</li>
                    <li><img src={Check} width="30px" alt="check"></img> Detailed ROI Analysis & Performance Insights</li>
                    <li><img src={Check} width="30px" alt="check"></img> Advanced Automation & Strategies</li>
                    <li><img src={Check} width="30px" alt="check"></img> Weekly Ad Account Updates & Detailed Reporting</li>
                    <li><img src={Check} width="30px" alt="check"></img> Enterprise-Level Custom Solutions</li>
                    </ul>
                </div>
                </div>

                </div>

            </div>

            <div className={`tab-pane fade show ${selectedOption === "SOCIAL MEDIA" ? "active" : ""}`} id="pills-socialmedia" role="tabpanel" aria-labelledby="pills-socialmedia-tab">
            <div className='row justify-content-center'>

                <div className='col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12 mb-4' data-aos="fade-up" data-aos-delay="00">
                <div className='pricing-box'>
                    <h3>Essential Plan</h3>
                    <p>Basic social media management for businesses looking for consistent, scheduled content on two platforms.</p>
                    <p><span className='pricing-box-price-first'>From </span><span className='pricing-box-price'>£129+</span> <span className='pricing-box-price-last'>/ Month</span></p>
                    <Link
                            data-aos="fade-up"
                            data-aos-delay="00"
                            to='/book-a-meeting'
                            className="btn"
                          >
                            SCHEDULE A CALL <img style={{ marginTop: '-4px', marginLeft: '5px' }} src={Calendar} width="25px " alt='calendar-icon'></img>
                          </Link>
                    <ul>
                    <li><img src={Check} width="30px" alt="check"></img> Two Posts per Week</li>
                    <li><img src={Check} width="30px" alt="check"></img> Two Social Media Platforms</li>
                    <li><img src={Check} width="30px" alt="check"></img> Mirrored Content Across Platforms</li>
                    <li><img src={Check} width="30px" alt="check"></img> Scheduled Posting (Not Live)</li>
                    <li><img src={Check} width="30px" alt="check"></img> Basic Analytics Reports</li>
                    <li><img src={Check} width="30px" alt="check"></img> Platform Setup & Branding</li>
                    </ul>
                </div>
                </div>

                <div className='col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12 mb-4' data-aos="fade-up" data-aos-delay="00">
                <div className='pricing-box'>
                    <h3>Growth Plan</h3>
                    <p>Enhanced social media management with more content, better platform adaptation, and audience engagement strategies.</p>
                    <p><span className='pricing-box-price-first'>From </span><span className='pricing-box-price'>£229+</span> <span className='pricing-box-price-last'>/ Month</span></p>
                    <Link
                            data-aos="fade-up"
                            data-aos-delay="00"
                            to='/book-a-meeting'
                            className="btn"
                          >
                            SCHEDULE A CALL <img style={{ marginTop: '-4px', marginLeft: '5px' }} src={Calendar} width="25px " alt='calendar-icon'></img>
                          </Link>
                    <ul>
                    <li><img src={Check} width="30px" alt="check"></img> Three Posts per Week</li>
                    <li><img src={Check} width="30px" alt="check"></img> Up to Three Social Media Platforms</li>
                    <li><img src={Check} width="30px" alt="check"></img> Platform-Specific Content Adjustments</li>
                    <li><img src={Check} width="30px" alt="check"></img> Scheduled & Real-Time Posting Options</li>
                    <li><img src={Check} width="30px" alt="check"></img> Monthly Performance Reports & Insights</li>
                    <li><img src={Check} width="30px" alt="check"></img> Hashtag Research & Strategy</li>
                    <li><img src={Check} width="30px" alt="check"></img> Audience Engagement & Interaction</li>
                    </ul>
                </div>
                </div>

                <div className='col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12 mb-4' data-aos="fade-up" data-aos-delay="00">
                <div className='pricing-box pricing-box-enterprise'>
                    <h3>Enterprise Plan</h3>
                    <p>Comprehensive social media management for larger brands, including in-depth strategy, content creation, and audience building.</p>
                    <p><span className='pricing-box-price-first'>Lets Talk!</span></p>
                    <Link
                            data-aos="fade-up"
                            data-aos-delay="00"
                            to='/book-a-meeting'
                            className="btn"
                          >
                            SCHEDULE A CALL <img style={{ marginTop: '-4px', marginLeft: '5px' }} src={Calendar} width="25px " alt='calendar-icon'></img>
                          </Link>
                    <ul>
                    <li><img src={Check} width="30px" alt="check"></img> Custom Posting Schedule (Up to Twice Daily)</li>
                    <li><img src={Check} width="30px" alt="check"></img> Four+ Social Media Platforms</li>
                    <li><img src={Check} width="30px" alt="check"></img> Advanced Audience Targeting & Interaction</li>
                    <li><img src={Check} width="30px" alt="check"></img> Bespoke Content Creation (Videos, Graphics, etc.)</li>
                    <li><img src={Check} width="30px" alt="check"></img> Real-Time Posting & Community Engagement</li>
                    <li><img src={Check} width="30px" alt="check"></img> Detailed Monthly Reports & Analysis</li>
                    <li><img src={Check} width="30px" alt="check"></img> Influencer & Partnership Strategy</li>
                    <li><img src={Check} width="30px" alt="check"></img> Priority Support & Strategy Meetings</li>
                    </ul>
                </div>
                </div>

                </div>

            </div>
        </div>

        <div className='pricing-section-button text-center pb-5 pt-3' data-aos="fade-up" data-aos-delay="00" >
            <p className="mb-3">
                Not finding what you're looking for? Or have something specific in mind?
                <br />
                <span>Schedule a call with us — we’re confident we can meet your needs.</span>
            </p>
            <Link
                            data-aos="fade-up"
                            data-aos-delay="00"
                            to='/book-a-meeting'
                            className="btn"
                          >
                            GET YOUR FREE CONSULTATION <img style={{ marginTop: '-4px', marginLeft: '5px' }} src={arrowup} width="20px " alt='calendar-icon'></img>
                          </Link>
            </div>


        </div>


    </div>

  );
};

export default Pricing;
